import Highlights from "components/common/Highlights";
import CTABanner from "components/common/footer-banners/CTABanner";
import HeroLayout5 from "components/common/hero/HeroLayout5";
import IndustryLeadersCards from "components/common/sliders/IndustryLeadersCards";
import GlobalLayout from "components/page/GlobalLayout";
import { StaticQuery, graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function VendorScorecard() {
  const renderContent = data => {
    const highlightSection = {
      title: "What You’ll Cover:",
      image: data.highlightImage,
      list: [
        {
          title: "Step 1: Identifying your security pain points ",
          description:
            "to tailor your vendor evaluation effectively and prioritize features aligning with your organizations needs.",
        },
        {
          title: "Step 2: Use the checklist ",
          description: "to assess vendors based on essential criteria. ",
        },
        {
          title: "Step 3: Analyze the results ",
          description: "from the checklist and make an informed decision.",
        },
      ],
    };

    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>
            Physical Security Provider Scorecard: Find the Best Solution for
            Your Organization
          </title>
          <meta
            name="description"
            content="Find the perfect physical security solution for your business with this provider scorecard. Evaluate essential factors like system usability, remote access, and footage management effortlessly."
          />
          <meta
            property="og:image"
            content="https://rhombus.com/img/MetaTag-scorecard.png"
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <HeroLayout5
          pretTitle="RESOURCE GUIDE"
          title="Physical Security Provider Scorecard"
          blurb="Discovering the ideal security provider for your business can be a meticulous process. Utilize this checklist to evaluate crucial factors like system usability, remote access capabilities, and effortless footage management, guiding you towards the perfect fit."
          formId="6304ca37-d2fd-4be2-a6ae-9b974f9af45f"
          formTitle="Download the Resource Guide"
        />
        <Highlights
          data={highlightSection}
          center
          color="var(--white)"
          sectionWidth="55%"
          inlineTitle
        />
        <IndustryLeadersCards color="var(--nuetral-100)" />
        <CTABanner />
      </GlobalLayout>
    );
  };
  const GET_IMAGES = graphql`
    query {
      highlightImage: file(
        relativePath: { eq: "components/landing-pages/img/scorecard.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;
  return <StaticQuery render={renderContent} query={GET_IMAGES} />;
}
